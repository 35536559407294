<template>
  <v-container>
    <v-row>
      <v-col
        cols="12"
        sm="11"
      >
        <div :class="[`text-h4`]"> {{ $route.params.id }} </div>
      </v-col>
      <v-spacer></v-spacer>
      <v-col>
        <v-btn
          small
          icon
          @click="$router.go(-1)"
        >
          <v-icon>mdi-page-previous-outline</v-icon>
        </v-btn>
      </v-col>
    </v-row>
    <v-row>
      <v-col>
        <end-point-form
          v-if="isFetch"
          v-model="endpointForm"
          @valid="valid = $event"
          :isCreated="true"
          ref="endpoint"
          :subscriptionsList="subscriptions"
          :connectionsList="connections"
        />
      </v-col>
      <confirm-dialog v-model="isCancel" :type="dialogType" title="end-point" @confirm="confirmDialog()" />
    </v-row>
    <v-row>
      <v-spacer></v-spacer>
      <v-btn
        class="ml-auto mx-2"
        :disabled="!valid"
        rounded
        small
        color="success"
        @click="isCancel = true, dialogType = 'update'"
      >
        <v-icon left>
          mdi-pencil
        </v-icon>
        Update
      </v-btn>
      <v-btn
        class="mx-2 white--text"
        rounded
        small
        color="danger"
        @click="isCancel = true, dialogType = 'delete'"
      >
        <v-icon left>
          mdi-delete
        </v-icon>
        Delete
      </v-btn>
    </v-row>
  </v-container>
</template>

<script>
import { mapGetters } from 'vuex'
import api from '@/services/api'
import merge from 'deepmerge'
import endpointTemplateForm from '@/template/endpointTemplate.json'

function isEmpty (obj) {
  for (let prop in obj) {
    if (Object.prototype.hasOwnProperty.call(obj, prop)) {
      return false
    }
  }
  return JSON.stringify(obj) === JSON.stringify({})
}

export default {
  name: 'EndPointEdit',
  components: {
    EndPointForm: () => import('@/components/end-point/EndPointForm'),
    ConfirmDialog: () => import('@/components/ConfirmDialog')
  },
  data: () => ({
    valid: true,
    isCancel: false,
    dialogType: 'cancel',
    isJson: false,
    isFetch: false,
    connections: [],
    subscriptions: [],
    endpointForm: {},
    test: {},
    id: 0
  }),
  methods: {
    fetchEndPoint: function () {
      console.log(this.$route.params.id.split(/:/g).pop())
      api.get(`/api/services/${this.fiwareService}/endpoints/${this.$route.params.id.split(/:/g).pop()}`, null, null, this.$store.state.tokenRPT).then((response) => {
        // console.log(response)
        if (response.status === 200) {
          response.json().then((json) => {
            this.test = json
            this.endpointForm = this.convertEndpointToFormz(json)
            this.isFetch = true
          })
        }
      })
    },
    convertEndpointToFormz: function (endpoint) {
      let endpointForm = JSON.parse(JSON.stringify(endpointTemplateForm))
      let processors = JSON.parse(endpoint.processors)
      endpointForm.name = this.$route.params.id.split(/:/g).pop()
      endpointForm.protocol = endpoint.protocol
      if (endpoint.protocol === 'http') {
        endpointForm.httpOption = endpoint.httpOption
      } else if (endpoint.protocol === 'mqtt') {
        endpointForm.mqttOption.connection = endpoint.mqttOption.connection._id
        endpointForm.mqttOption.subscription = endpoint.mqttOption.subscription._id
      }
      endpointForm.parseRequest = endpoint.parseRequest || { errorHandler: null }
      endpointForm.processors = this.processorsList(processors)
      return endpointForm
    },
    processorsList: function (processors) {
      let processorL = {
        L: []
      }
      if (processors.L) {
        processors.L.forEach(l => {
          let processorLL = {
            L: []
          }
          l.L.forEach(ll => {
            const llm = ll.M
            console.log(ll)
            const proccessorName = llm.name.S.split(/\./g).pop()
            const template = JSON.parse(JSON.stringify(endpointTemplateForm.template))
            console.log(template.taskList[proccessorName])
            if (template.taskList[proccessorName]) {
              const llProcessorTask = JSON.parse(JSON.stringify(template.taskList[proccessorName]))
              const llOptionTemplate = llm.optionTemplate ? llm.optionTemplate : {}
              let llMergeOptionTemplate = merge(llProcessorTask, llOptionTemplate)
              let processorTemplate = JSON.parse(JSON.stringify(template.processorTemplate))
              processorTemplate.name = llm.name
              processorTemplate.guard = llm.guard || null
              if (!isEmpty(llMergeOptionTemplate) && llMergeOptionTemplate.M && llMergeOptionTemplate.M.processors) {
                llMergeOptionTemplate.M.processors = this.processorsList(llMergeOptionTemplate.M.processors)
              }
              processorTemplate.optionTemplate = llMergeOptionTemplate
              processorLL.L.push({ M: processorTemplate })
            }
          })
          if (processorLL.L.length > 0) {
            processorL.L.push(processorLL)
          }
        })
      }
      return processorL
    },
    guardToFormz: function (guard) {
      let guardList = []
      const logicOperator = ['and', 'or', 'not']
      const logicOperator2 = ['==', '>=', '<', '<=']
      for (const mKey in guard && guard.M) {
        if (logicOperator.includes(mKey.toLowerCase())) {
          this.id += 1
          guard.M[mKey].L.forEach((element, index) => {
            guardList.push(...this.guardToFormz(element))
            if (index + 1 < guard.M[mKey].L.length) {
              this.id += 1
              guardList.push({ index: this.id, value: mKey })
            }
          })
        } else if (logicOperator2.includes(mKey)) {
          guard.M[mKey].L.forEach((element, index) => {
            let str = ''
            this.id += 1
            for (const eKey in element) {
              if (eKey === 'M') {
                str = str.concat(element[eKey].var.S)
              } else if (eKey === 'S') {
                str = str.concat(`"${element[eKey]}"`)
              }
            }
            guardList.push({ index: this.id, value: str })
            if (index + 1 < guard.M[mKey].L.length) {
              this.id += 1
              guardList.push({ index: this.id, value: mKey })
            }
          })
        }
      }
      return guardList
    },
    confirmDialog: function () {
      if (this.dialogType === 'delete') {
        this.$refs.endpoint.deleteEndPoint()
      } else if (this.dialogType === 'update') {
        this.$refs.endpoint.updateEndPoint()
      }
    },
    taskHttpRequestDataToForm: function (data) {
      let objData = null
      for (const keyData in data) {
        if (keyData === 'M') {
          objData = {}
          for (const keyM in data.M) {
            objData[keyM] = this.taskHttpRequestDataToForm(data.M[keyM])
          }
        } else if (keyData === 'L') {
        } else {
          objData = data[keyData]
        }
      }
      return objData
    },
    fetchMQTTConnections: function () {
      let vm = this
      api.get(`/api/services/${this.fiwareService}/connections`, null, null, this.$store.state.tokenRPT).then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            vm.connections = json
            for (let connection of json) {
              vm.fetchMQTTSubscriptions(connection['_source']['name'])
            }
          })
        }
      })
    },
    fetchMQTTSubscriptions: function (connection) {
      let vm = this
      api.get(`/api/services/${this.fiwareService}/connections/${connection}/subscriptions`, null, null, this.$store.state.tokenRPT).then((response) => {
        if (response.status === 200) {
          response.json().then((json) => {
            vm.subscriptions = [...vm.subscriptions, ...json]
          })
        }
      })
    }
  },
  computed: {
    ...mapGetters(['fiwareService']),
    ty: function () {
      return this.$route.params.id.split(/:/g).pop()
    }
  },
  watch: {
    '$store.state.fiwareService': function () {
      // this.$router.back()
      this.fetchMQTTConnections()
      this.$router.go(-1)
    }
  },
  created () {
    this.fetchEndPoint()
    this.fetchMQTTConnections()
  }
}
</script>
